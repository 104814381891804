import "./AllInstallerDistanceReportPage.css";
import { useState } from "react";
import client from "../../client";
import {
  Alert,
  Button,
  Container,
  Slide,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { InstallerReportWorkBook } from "../../interfaces/InstallerReportWorkBook";
import InstallerReportWorkBookSelector from "../../components/InstallerReportWorkBookSelector/InstallerReportWorkBookSelector";

const AllInstallerDistanceReportPage = () => {
  const [marketplaceStoreDetails, setMarketplaceStoreDetails] =
    useState<InstallerReportWorkBook>({});
  const [
    reportRequestSubmittedSuccessfullyDialog,
    setReportRequestSubmittedSuccessfullyAlert,
  ] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const onMarketplaceStoreDetailsChange = (
    installerReportWorkBook: InstallerReportWorkBook
  ) => {
    setMarketplaceStoreDetails(installerReportWorkBook);
  };

  const onEmailChange = (event: any) => {
    const email = event.target.value;
    if (!!email) {
      setEmail(email);
    }
  };

  const onSubmit = () => {
    console.log(`onSubmit() called`);
    if (
      !!marketplaceStoreDetails &&
      !!marketplaceStoreDetails.uploadedFile &&
      !!marketplaceStoreDetails.fileName &&
      !!marketplaceStoreDetails.selectedWorkSheetName &&
      !!marketplaceStoreDetails.selectedWorkSheetIndex
    )
      client.axisFunctions
        .createMarketplaceInstallerCountReportAllInstallers(
          "marketplaceStoresFile",
          marketplaceStoreDetails.uploadedFile,
          marketplaceStoreDetails.fileName,
          marketplaceStoreDetails.selectedWorkSheetName,
          marketplaceStoreDetails.selectedWorkSheetIndex,
          email,
          [5, 10, 25]
        )
        .then(() => {
          setReportRequestSubmittedSuccessfullyAlert(true);
        });
  };

  return (
    <div className="AllInstallerDistanceReportPage">
      <br />
      <Typography variant="h3" gutterBottom>
        Installer Distance Report - All
      </Typography>
      <br />
      <br />
      <Container maxWidth="sm">
        <Stack spacing={2}>
          <InstallerReportWorkBookSelector
            installerReportWorkBook={marketplaceStoreDetails}
            onInstallerReportWorkBookChange={onMarketplaceStoreDetailsChange}
          />
          <TextField
            value={email}
            onChange={onEmailChange}
            label="Enter your email"
          />
          <Button variant="contained" onClick={onSubmit}>
            Submit
          </Button>
          <br/>
          <br/>
          <Typography variant="h5" gutterBottom>
              ***NOTICE***
          </Typography>
            <p>
                Apologies but due to some technical issues we are not able to send the report over email,
                hence we request you to please download the file manually from Azure Portal until this issue
                gets fixed
                <br/>
                Please open the below link and go to <b>installer-service/marketplace-installer-count-report </b>
                and download the latest file with new at the end
                <br/>
                <br/>
                <a target="_blank"
                   href={"https://portal.azure.com/#view/Microsoft_Azure_FileStorage/FileShareMenuBlade/~/browse/storageAccountId/%2Fsubscriptions%2Fd7583b83-78e5-46ee-8fec-faf442a3eaeb%2FresourceGroups%2FTB-AXIS-PROD-RG%2Fproviders%2FMicrosoft.Storage%2FstorageAccounts%2Ftbaxisprodsa/path/tb-axis-prod-fs/protocol/SMB"}
                   rel="noreferrer"
                >Click here to go to storage account</a>
            </p>
        </Stack>
      </Container>
      <Snackbar
        open={reportRequestSubmittedSuccessfullyDialog}
        onClose={() => setReportRequestSubmittedSuccessfullyAlert(false)}
        autoHideDuration={3000}
        TransitionComponent={TransitionRight}
      >
        <Alert severity="success">Report Request Submitted Successfully</Alert>
      </Snackbar>
    </div>
  );
};

const TransitionRight = (props: any) => {
  return <Slide {...props} direction="right" />;
};

export default AllInstallerDistanceReportPage;
